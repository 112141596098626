import {
    RouteReuseStrategy,
    ActivatedRouteSnapshot,
    DetachedRouteHandle,
  } from "@angular/router";
  
  export class CustomRouteReuseStrategy implements RouteReuseStrategy {
    private storedRoutes = new Map<string, DetachedRouteHandle>();
    private routesToDetach = ['users', 'payer/plans', 'payer/carriers']  

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
      const filterPresent = !!document.getElementsByClassName("form-control ng-valid ng-dirty ng-touched").item(0)
      return filterPresent && this.routesToDetach.includes(route.routeConfig.path) ;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
      this.storedRoutes.set(route.routeConfig.path, handle);
    }
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return (
          !!route.routeConfig && !!this.storedRoutes.get(route.routeConfig.path)
        );
      }
    
      retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        return this.storedRoutes.get(route.routeConfig.path);
      }
    shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        curr: ActivatedRouteSnapshot
      ): boolean {
        return future.routeConfig === curr.routeConfig;
      }
  }